import { getMapsHelper } from './util/mapsHelper';

export const initMultiMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			mapHelper.createMap({
				element: document.querySelector('#multi_map'),
				locationElementSelector: '.data_source',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `
							<a class="map_pin" href="${el.getAttribute('link')}">
								<img src="/static/images/icons/map_pin.svg" alt="Visit facility" />
								<span>${el.getAttribute('count')}</span>
							</a>
						`
					};
				},
			});
		});
};